export const DATE_FORMAT = 'MMM d, yyyy'

export const API_DATE_FORMAT = 'yyyy-MM-dd'

export const DEFAULT_MIN_DATE = new Date('1978-01-01')
export const TODAY_DATE = new Date()
export const DEFAULT_MONTHS_SHOWN = 2

export const COMPARISON_TYPES = {
  previousPeriod: 'previousPeriod',
  previousYear: 'previousYear',
  previousPeriods: 'previousPeriods'
}
